module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cashfree","short_name":"Cashfree","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/img/cashfree-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28c4b84dd212dcbbd293f52e241bb1ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blogrevamp.cashfree.com/graphql","html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true,"generateWebpImages":false},"type":{"Menu":{"exclude":true},"MenuItem":{"exclude":true},"UserRole":{"exclude":true},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"ContentType":{"exclude":true},"Category":{"exclude":true},"Page":{"exclude":true},"Taxonomy":{"exclude":true},"User":{"exclude":true},"Post":{"exclude":true},"Tag":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"perPage":100,"previewRequestConcurrency":5},"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
